import { Link, navigate } from "gatsby";
import React, { useState, useEffect } from "react";
import SelectLanguage from "../components/SelectLanguage";
import Img_person from "../images/icons/person.svg";
import { isLoggedIn, logout } from "../services/Auth";
import { setUserData } from "../data/setUserData";
import { Menu, Dropdown, Form, DatePicker, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { SUCCESS } from "state/constants/actions";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { clearAllState, saveState } from "state/utils/localStorage";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import posthog from "posthog-js";

const Header = (props) => {
  const {
    loginWithRedirect,
    logout: logoutFromAuth0,
    isAuthenticated,
    isLoading,
  } = useAuth0();
  let userData = setUserData();

  const guestInformation = useSelector(
    (store) => store.guestInformation.member.data
  );
  const { member: memberPoint } = useSelector((state) => state.memberPoint);
  const [isMobileView, setMobileView] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const [show_nav_mobile, setNavMobile] = useState(false);
  const [show_book_now_widget, setBookNowWidget] = useState(false);
  const screens = useBreakpoint();

  useEffect(() => {
    if (props.mobileWidgetStatus) {
      setBookNowWidget(true);
    }
  }, [props.mobileWidgetStatus]);
  const [hotelError, setHotelError] = useState(false);
  const [stayError, setStayError] = useState(false);

  const [form] = Form.useForm();

  const [arrival_date, setArrivalDate] = useState();
  const [departure_date, setDepartureDate] = useState();
  const [widget_default_value] = useState({
    start_date: "",
    end_date: "",
    hotel: "Bali",
    adults_count: "1",
    childs_count: "0",
    room: "1",
  });

  const [adult_select_dropdown, setAdultSelectDropdown] = useState([1, 2, 3]);
  const [children_select_dropdown, setChildrenSelectDropdown] = useState([
    0,
    1,
    2,
  ]);

  const isAuth0Enabled = process.env.GATSBY_ENABLE_AUTH0 === "true";
  const userHasLoggedIn = isAuth0Enabled ? isAuthenticated : isLoggedIn();

  const handleChangeaValue = (changedValues, allValues) => {
    if (changedValues.room != undefined) {
      form.setFieldsValue({
        adults_count: changedValues.room,
        childs_count: "0",
      });
    }

    let newSelectAdult = [];
    for (var i = allValues.room; i < allValues.room * 3 + 1; i++) {
      newSelectAdult.push(i);
    }
    setAdultSelectDropdown(newSelectAdult);

    let newSelectChildren = [];
    if (changedValues.room != undefined) {
      for (var i = 0; i < allValues.room * 3 - allValues.room + 1; i++) {
        newSelectChildren.push(i);
      }
    } else {
      for (
        var i = 0;
        i < allValues.room * 3 - allValues.adults_count + 1;
        i++
      ) {
        newSelectChildren.push(i);
      }
    }
    setChildrenSelectDropdown(newSelectChildren);
  };

  const { member: dataMemberPoint, uiState } = useSelector(
    (state) => state.dataMemberPoint
  );

  useEffect(() => {
    let currentHideNav = window.innerWidth <= 800;
    setMobileView(currentHideNav);
    setHideNav(currentHideNav);
    setNavMobile(false);
    setUserData();
  }, [dataMemberPoint, memberPoint]);

  useEffect(() => {
    if (uiState === SUCCESS) {
      setTimeout(function () {
        headerPage();
      }, 200);
    }
  }, [dataMemberPoint, setUserData, userData]);

  if (typeof window !== "undefined") {
    window.addEventListener("click", (event) => {
      if (show_book_now_widget == true || props.mobileWidgetStatus == true) {
        setBookNowWidget(!show_book_now_widget);
        if (typeof props.setMobileWidgetStatus === "function") {
          props.setMobileWidgetStatus(!show_book_now_widget);
        }
      }
    });
  }

  function disabledDateArrival(current) {
    return current && current < moment().subtract(1, "days");
  }

  function disabledDateDeparture(current) {
    return current && current < moment(arrival_date).add(1, "days");
  }

  function onChangeArrival(date, dateString) {
    setArrivalDate(date);
    setDepartureDate(moment(date).add(1, "days"));
    form.setFieldsValue({ end_date: moment(date).add(1, "days") });
  }

  function onChangeDeparture(date, dateString) {
    setDepartureDate(date);
    if (!Boolean(arrival_date)) {
      setArrivalDate(moment(date).subtract(1, "days"));
      form.setFieldsValue({ start_date: moment(date).subtract(1, "days") });
    }
  }

  const handleLogout = (event) => {
    event.preventDefault();
    if (isAuth0Enabled) {
      clearAllState();
      props.setLoading(isLoading);
      logoutFromAuth0({
        logoutParams: {
          federated: false,
          returnTo:
            props.langkey === "en"
              ? window.location.origin
              : `${window.location.origin}/${props.langkey}/`,
        },
        clientId: process.env.GATSBY_AUTH0_CLIENT_ID,
      });
    } else {
      props.setLoading(true);
      logout(() => navigate(`${props.home_link}login`));
    }
  };

  const getMemberData = (isLogin, userInfo) => ({
    MemberPoints:
      isLogin && userInfo?.rewardsInfo ? userInfo.rewardsInfo.points || 0 : 0,
    MemberLevel:
      isLogin && userInfo?.rewardsInfo ? userInfo.rewardsInfo.level || "" : "",
  });

  const handleBookNow = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setBookNowWidget(!show_book_now_widget);
    //triger posthog
    posthog?.capture("rewards_booking_widget_opened", {
      ...getMemberData(userHasLoggedIn, guestInformation),
    });
    if (typeof props.setMobileWidgetStatus === "function") {
      props.setMobileWidgetStatus(!show_book_now_widget);
    }
  };

  const onFinishFailed = (errorInfo) => {
    const { errorFields } = errorInfo;
    const thirdError = errorFields[2].errors[0];
    const firstError = errorFields[0].errors[0];
    if (thirdError && firstError) {
      setHotelError(true);
      setStayError(true);
    } else {
      setHotelError(false);
      setStayError(false);
    }
  };

  const handleSubmit = (fieldsValue) => {
    if (typeof window === "undefined") return;

    //triger posthog
    posthog?.capture("rewards_booking_widget_submitted", {
      hotel: fieldsValue["hotel"],
      adults: fieldsValue["adults_count"],
      children: fieldsValue["childs_count"],
      room: fieldsValue["room"],
      arrival_date: arrival_date.format("YYYY-MM-DD"),
      departure_date: departure_date.format("YYYY-MM-DD"),
      ...getMemberData(userHasLoggedIn, guestInformation),
    });

    if (
      fieldsValue["hotel"] === "ARSB" ||
      fieldsValue["hotel"] === "RJB" ||
      fieldsValue["hotel"] === "VILLA" ||
      fieldsValue["hotel"] === "SEGARA"
    ) {
      window.location.href = `${process.env.GATSBY_ABE_URL}/${
        props.langkey
      }/rooms?date_from=${arrival_date.format(
        "YYYY-MM-DD"
      )}&date_to=${departure_date.format(
        "YYYY-MM-DD"
      )}&location_code=Bali&property_main=${fieldsValue["hotel"]}&rooms=${
        fieldsValue["room"]
      }&adults=${fieldsValue["adults_count"]}&children=${
        fieldsValue["childs_count"]
      }&booking_code=${userHasLoggedIn ? "MEMBERRATES" : ""}&currency=${
        props.langkey === "zh" ? "CNY" : "USD"
      }&utm_source=BookingWidgetRewards&utm_medium=Form&utm_campaign=ABELanding${
        userHasLoggedIn ? "&refer=AYANAREWARDS" : ""
      }`;
    } else if (fieldsValue["hotel"] === "AKWB") {
      window.location.href = `${process.env.GATSBY_ABE_URL}/${
        props.langkey
      }/rooms?date_from=${arrival_date.format(
        "YYYY-MM-DD"
      )}&date_to=${departure_date.format(
        "YYYY-MM-DD"
      )}&location_code=Komodo&property_main=${fieldsValue["hotel"]}&rooms=${
        fieldsValue["room"]
      }&adults=${fieldsValue["adults_count"]}&children=${
        fieldsValue["childs_count"]
      }&booking_code=${userHasLoggedIn ? "MEMBERRATES" : ""}&currency=${
        props.langkey === "zh" ? "CNY" : "USD"
      }&utm_source=BookingWidgetRewards&utm_medium=Form&utm_campaign=ABELanding${
        userHasLoggedIn ? "&refer=AYANAREWARDS" : ""
      }`;
    } else if (fieldsValue["hotel"] === "AMJ") {
      window.location.href = `${process.env.GATSBY_ABE_URL}/${
        props.langkey
      }/rooms?date_from=${arrival_date.format(
        "YYYY-MM-DD"
      )}&date_to=${departure_date.format(
        "YYYY-MM-DD"
      )}&location_code=Jakarta&property_main=JKTHB&rooms=${
        fieldsValue["room"]
      }&adults=${fieldsValue["adults_count"]}&children=${
        fieldsValue["childs_count"]
      }&booking_code=${userHasLoggedIn ? "MEMBERRATES" : ""}&currency=${
        props.langkey === "zh" ? "CNY" : "USD"
      }&utm_source=BookingWidgetRewards&utm_medium=Form&utm_campaign=ABELanding${
        userHasLoggedIn ? "&refer=AYANAREWARDS" : ""
      }`;
    } else if (fieldsValue["hotel"] === "DXKR") {
      window.location.href = `${process.env.GATSBY_ABE_URL}/${
        props.langkey
      }/rooms?date_from=${arrival_date.format(
        "YYYY-MM-DD"
      )}&date_to=${departure_date.format(
        "YYYY-MM-DD"
      )}&location_code=Karawang&property_main=${fieldsValue["hotel"]}&rooms=${
        fieldsValue["room"]
      }&adults=${fieldsValue["adults_count"]}&children=${
        fieldsValue["childs_count"]
      }&booking_code=${userHasLoggedIn ? "MEMBERRATES" : ""}&currency=${
        props.langkey === "zh" ? "CNY" : "USD"
      }&utm_source=BookingWidgetRewards&utm_medium=Form&utm_campaign=ABELanding${
        userHasLoggedIn ? "&refer=AYANAREWARDS" : ""
      }`;
    }
  };

  const uiLocaleForAuth0LogingForm =
    props.langkey === "zh" ? "zh-CN" : props.langkey;

  const profilePage =
    props.langkey === "en" ? "/member" : `/${props.langkey}/member`;

  const menu = (
    <Menu>
      <Menu.Item>
        <a href={profilePage}>{props.content.profile_label}</a>
      </Menu.Item>

      <Menu.Item>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={handleLogout}>{props.content.logout_label}</a>
      </Menu.Item>
    </Menu>
  );

  const isProfileCompleted = userData?.firstname && userData?.lastname;

  const profileDetail = isProfileCompleted
    ? `${
        guestInformation
          ? guestInformation?.guest.firstName
          : userData?.firstname
      } | ${
        guestInformation
          ? guestInformation?.rewardsInfo.points.toLocaleString()
          : userData?.balance.toLocaleString()
      } ${props.content.point_label}`
    : ` | 0  ${props.content.point_label}`;

  const handleSignup = () => {
    const href =
      props.langkey === "en" ? `/member` : `/${props.langkey}/member`;
    saveState("redirectUrl", href);
    loginWithRedirect({
      authorizationParams: {
        ui_locales: uiLocaleForAuth0LogingForm,
        screen_hint: "signup",
        redirect_uri:
          props.langkey === "en"
            ? `${window.location.origin}/callback`
            : `${window.location.origin}/${props.langkey}/callback`,
      },
      appState: {
        target: "redirectUrl",
      },
    });
  };

  const headerPage = () => {
    userData = setUserData();
    return (
      <>
        <header className="header">
          <div className="header--top headroom headroom--top headroom--not-bottom">
            <SelectLanguage
              selected={props.lang_selected_name}
              langs={props.langs}
            />

            <div className="header__account">
              <div className="header__account__title">
                <img src={Img_person} alt="" className="marr--sm" />
                {!userHasLoggedIn ? (
                  process.env.GATSBY_ENABLE_AUTH0 === "true" ? (
                    <span
                      onClick={() => {
                        const href =
                          props.langkey === "en"
                            ? `/member`
                            : `/${props.langkey}/member`;
                        saveState("redirectUrl", href);
                        loginWithRedirect({
                          authorizationParams: {
                            ui_locales: uiLocaleForAuth0LogingForm,
                            redirect_uri:
                              props.langkey === "en"
                                ? `${window.location.origin}/callback`
                                : `${window.location.origin}/${props.langkey}/callback`,
                          },
                          appState: {
                            target: "redirectUrl",
                          },
                        });
                      }}>
                      {props.content.sign_in}
                    </span>
                  ) : (
                    <Link to={`${props.home_link}login`}>
                      {props.content.sign_in}
                    </Link>
                  )
                ) : (
                  <Dropdown overlay={menu}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}>
                      {profileDetail}
                      <DownOutlined />
                    </a>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>

          <div className="header--middle">
            <div className="header--middle__content">
              <Link to={props.home_link} className="header__logo">
                <img
                  src="https://resource.ayana.com/live/rewards/logo/logo.png"
                  alt="rewards-logo"
                />
              </Link>

              <label
                className="nav__trigger"
                onClick={(e) => {
                  setHideNav(true);
                  setNavMobile(!show_nav_mobile);
                }}>
                <div></div>
                <div></div>
                <div></div>
              </label>
            </div>

            <nav
              className="navmenu"
              id="mobile__menu"
              style={
                hideNav
                  ? show_nav_mobile
                    ? { display: "block" }
                    : { display: "none" }
                  : { display: "block" }
              }>
              {/* <Link to={props.home_link + "loyalty-update"} key="loyalty-update">
                {props.content.menu_loyalty_update}
              </Link> */}
              <Link to={props.home_link + "promotion"} key="promotion">
                {props.content.menu_promotion}
              </Link>
              <Link
                to={props.home_link + "member-benefits"}
                key="member-benefits">
                {props.content.menu_member_benefit}
              </Link>
              <Link to={props.home_link + "partner"} key="partners">
                {props.content.menu_partner}
              </Link>
              <Link to={props.home_link + "reward"}>
                {props.content.menu_reedem}
              </Link>
              <Link to={props.home_link + "faq"} key="faq">
                {props.content.menu_faq}
              </Link>

              {!userHasLoggedIn ? (
                <>
                  {isAuth0Enabled ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      onClick={() => {
                        const href =
                          props.langkey === "en"
                            ? "/member/#feedback"
                            : `/${props.langkey}/member/#feedback`;
                        saveState("redirectUrl", href);
                        loginWithRedirect({
                          authorizationParams: {
                            ui_locales: uiLocaleForAuth0LogingForm,
                            redirect_uri:
                              props.langkey === "en"
                                ? `${window.location.origin}/callback`
                                : `${window.location.origin}/${props.langkey}/callback`,
                          },
                          appState: {
                            target: "redirectUrl",
                          },
                        });
                      }}
                      key="contact_us">
                      {props.content.menu_contact_us}
                    </a>
                  ) : (
                    <Link to={`${props.home_link}login`} key="contact_us">
                      {props.content.menu_contact_us}
                    </Link>
                  )}
                  {isAuth0Enabled ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      onClick={() => {
                        const href =
                          props.langkey === "en"
                            ? `/member`
                            : `/${props.langkey}/member`;
                        saveState("redirectUrl", href);
                        loginWithRedirect({
                          authorizationParams: {
                            ui_locales: uiLocaleForAuth0LogingForm,
                            redirect_uri:
                              props.langkey === "en"
                                ? `${window.location.origin}/callback`
                                : `${window.location.origin}/${props.langkey}/callback`,
                          },
                          appState: {
                            target: "redirectUrl",
                          },
                        });
                      }}
                      key="login_mobile"
                      className="mobile--only">
                      {props.content.sign_in}
                    </a>
                  ) : (
                    <Link
                      to={`${props.home_link}login`}
                      key="login_mobile"
                      className="mobile--only">
                      {props.content.sign_in}
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link
                    to={props.home_link + "member/#feedback"}
                    key="contact_us"
                    onClick={(event) => {
                      isMobileView && setHideNav(true);
                    }}>
                    {props.content.menu_contact_us}
                  </Link>
                  <Link
                    to={props.home_link + "member"}
                    key="member"
                    className="mobile--only">
                    {props.content.profile_label}
                  </Link>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={handleLogout} className="mobile--only">
                    {props.content.logout_label}
                  </a>
                </>
              )}

              <Link
                to={props.home_link + "terms-and-conditions-8"}
                key="terms-and-conditions-8"
                className="mobile--only">
                {props.content.menu_tos}
              </Link>

              <Link
                to={props.home_link + "terms-and-conditions-8#data-privacy"}
                key="data-privacy"
                className="mobile--only">
                {props.content.menu_privacy}
              </Link>

              <SelectLanguage
                is_mobile={true}
                selected={props.lang_selected_name}
                langs={props.langs}
              />
              {screens.xs === true ? (
                <button
                  onClick={userHasLoggedIn ? handleBookNow : handleSignup}
                  className="button button--cta header--bottom__trigger">
                  {userHasLoggedIn
                    ? props.content.btn_book_now
                    : props.content.become_member}
                </button>
              ) : (
                <button
                  onClick={handleBookNow}
                  className="button button--cta header--bottom__trigger">
                  {props.content.btn_book_now}
                </button>
              )}
            </nav>
          </div>
          <div
            onClick={handleBookNow}
            className={`${show_book_now_widget && "header--bottom__overlay"}`}>
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
              className={`header--bottom ${
                show_book_now_widget ? "show-widget" : ""
              }`}>
              <Form
                form={form}
                name="bookwidget"
                size="middle"
                initialValues={widget_default_value}
                onFinish={handleSubmit}
                onValuesChange={handleChangeaValue}
                onFinishFailed={onFinishFailed}
                scrollToFirstError>
                <div className="header--bottom--inner datepicker2__container">
                  <div>
                    <div className="flex ">
                      <div className="home__book__col">
                        <div className="home__book__title">
                          {props.content.widget_label_arrival}
                        </div>
                        <div className="home__book__input">
                          <Form.Item
                            name="start_date"
                            className="m-0"
                            rules={[{ required: true }]}
                            help=" ">
                            <DatePicker
                              format="DD/MM/YYYY"
                              className="input input--sm input--subtle datepick datepicker2 datepicker2--from"
                              style={{
                                padding: "3px 10px",
                                border:
                                  stayError &&
                                  !Boolean(form.getFieldValue("start_date")) &&
                                  "1px solid #A80000",
                              }}
                              suffixIcon=""
                              showToday={false}
                              allowClear={false}
                              disabledDate={disabledDateArrival}
                              onChange={onChangeArrival}
                              placeholder="DD/MM/YYYY"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="home__book__col">
                        <div className="home__book__title">
                          {props.content.widget_label_departure}
                        </div>
                        <div className="home__book__input">
                          <Form.Item
                            name="end_date"
                            className="m-0"
                            rules={[{ required: true }]}
                            help=" ">
                            <DatePicker
                              format="DD/MM/YYYY"
                              className="input input--sm input--subtle datepick datepicker2 datepicker2--to"
                              style={{
                                padding: "3px 10px",
                                border:
                                  stayError &&
                                  !Boolean(form.getFieldValue("start_date")) &&
                                  "1px solid #A80000",
                              }}
                              suffixIcon=""
                              showToday={false}
                              allowClear={false}
                              disabledDate={disabledDateDeparture}
                              onChange={onChangeDeparture}
                              placeholder="DD/MM/YYYY"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    {stayError && !Boolean(form.getFieldValue("end_date")) && (
                      <p className="error__message">
                        {props.content.widget_stay_error}
                      </p>
                    )}
                  </div>
                  <div className="datepicker--mobile"></div>
                  <div className="home__book__col">
                    <div className="home__book__title">
                      {props.content.widget_label_choose_hotel}
                    </div>
                    <div className="home__book__input">
                      <Form.Item
                        name="hotel"
                        className="m-0"
                        rules={[
                          {
                            validator: (rule, value) => {
                              if (value === "Bali") {
                                return Promise.reject(
                                  props.content.widget_hotel_error
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}>
                        <select
                          id="hotel"
                          className="select input input--sm"
                          style={{
                            border:
                              hotelError &&
                              form.getFieldValue("hotel") === "Bali" &&
                              "1px solid #A80000",
                          }}>
                          <option value="Bali">
                            {props.content.widget_dropdown_hotel_option_choose}
                          </option>
                          <option value="AKWB">
                            AYANA Komodo Waecicu Beach
                          </option>
                          <option value="VILLA">AYANA Villas Bali</option>
                          <option value="ARSB">AYANA Resort Bali</option>
                          <option value="RJB">RIMBA by AYANA Bali</option>
                          <option value="SEGARA">AYANA Segara Bali</option>
                          <option value="AMJ">AYANA Midplaza, JAKARTA</option>
                          <option value="DXKR">Delonix Hotel Karawang</option>
                        </select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="flex home__book__col">
                    <div className="col">
                      <div className="home__book__title">
                        {props.content.widget_label_room}
                      </div>
                      <div className="home__book__count">
                        <Form.Item name="room" className="m-0">
                          <select id="room" className="select input input--sm">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col">
                      <div className="home__book__title">
                        {props.content.widget_label_adult}{" "}
                      </div>
                      <div className="home__book__count">
                        <Form.Item name="adults_count" className="m-0">
                          <select
                            id="adults_count"
                            className="select input input--sm">
                            {adult_select_dropdown.map((value, index) => {
                              return <option value={value}>{value}</option>;
                            })}
                          </select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col">
                      <div className="home__book__title">
                        {props.content.widget_label_child}
                      </div>
                      <div className="home__book__count">
                        <Form.Item name="childs_count" className="m-0">
                          <select
                            id="childs_count"
                            className="select input input--sm">
                            {children_select_dropdown.map((value, index) => {
                              return <option value={value}>{value}</option>;
                            })}
                          </select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="home__book__col">
                    <div className="home__book__title"></div>
                    <div className="home__book__action">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button button--sm button--cta button--full">
                          {props.content.btn_book_now}
                        </Button>
                      </Form.Item>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      onClick={handleBookNow}
                      className="button button--secondary tablet--only button--full button--sm header--bottom__trigger mart--md">
                      {props.content.widget_button_cancel}
                    </a>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </header>
        {(props.showBookNowButton ?? true) && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={userHasLoggedIn ? handleBookNow : handleSignup}
            className="button button--cta header--bottom__trigger tablet--only button--fixed">
            {userHasLoggedIn
              ? props.content.btn_book_now
              : props.content.become_member}
          </a>
        )}
      </>
    );
  };

  return <>{headerPage()}</>;
};

export default Header;
